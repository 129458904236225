import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class KDMZQuestionExtension extends TKCustomElementFactory {
    constructor() {
        super();
        this.groups = this.querySelectorAll('[data-question-group]');
        this.showValue = this.getAttribute('data-show-value');
        this.isNegation = this.hasAttribute('data-negation');
        this.onFurnitureOrderedChanged();
    }

    connectedCallback() {
        this.groups.length > 0 && this.groups.forEach((group) => {
            const inputFields = group.querySelectorAll('[data-question-main] input');
            this.registerClickListener(inputFields, group);
        });
    }

    registerClickListener(fields, group) {
        fields.forEach((field) => {
            this.toggleVisibility(group, field);
            field.addEventListener('click', this.handleClickListener.bind(this, group));
        });
    }

    handleClickListener(group, event) {
        this.toggleVisibility(group, event.target);
    }

    toggleVisibility(group, target) {
        const questionExtension = group.querySelector('[data-question-extension]');
        const isHidden = !(target.checked && target.value === this.showValue) ^ this.isNegation;
        questionExtension.hidden = isHidden;
        this.toggleRequirement(questionExtension, isHidden);
    }

    toggleRequirement(extension, isHidden) {
        const fieldElements = extension.querySelectorAll('input');
        fieldElements.forEach((element) => {
            const isSpecialElement = element.dataset.hasOwnProperty('specialRequirement');

            const furnitureInorderFieldYesElement = document.querySelector('[data-furniture-inorder-yes]');
            const furnitureRequiredElement = document.querySelector('[data-furniture-required]');

            if (isSpecialElement) {
                if (!furnitureInorderFieldYesElement) { return; }
                element.required = furnitureInorderFieldYesElement.checked && furnitureRequiredElement.checked;
            } else {
                element.required = !isHidden;
            }
        });
    }

    onFurnitureOrderedChanged() {
        const radioButtonsArray = [
            document.querySelector('[data-furniture-inorder-yes]'),
            document.querySelector('[data-furniture-inorder-no]'),
        ];

        radioButtonsArray.forEach((radioButtonElement) => {
            radioButtonElement.addEventListener('change', (e) => {
                const orderNumberInputFieldElement = document.querySelector('[data-special-requirement]');

                if (!orderNumberInputFieldElement) { return; }
                orderNumberInputFieldElement.required = radioButtonsArray[0].checked;
            });
        });

        /* furnitureInorderFieldYesElement.addEventListener("change", (e) => {
            let orderNumberInputFieldElement = document.querySelector("[data-special-requirement]");

            console.log("change");

            if(!orderNumberInputFieldElement) { return; }
            orderNumberInputFieldElement.required = furnitureInorderFieldYesElement.checked;

        }); */
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log(`name: ${name} oldValue; ${oldValue} newValue: ${newValue}`);
    }
}
