/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import TKFormElement from '@tk/controls/tk.form.element';

export default class KDMZFormElement extends TKFormElement {
    select?: HTMLSelectElement;
    checkbox?: HTMLInputElement;
    range?: HTMLInputElement;
    evt : Event;

    constructor() {
        super();

        this.select = this.querySelector('select') as HTMLSelectElement;
        this.checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement;
        this.range = this.querySelector('input[type="range"]') as HTMLInputElement;
        this.evt = new Event('change');
    }

    connectedCallback() {
        super.connectedCallback();
        const onSelectChange = this.handleSelectChange.bind(this);
        const onCheckboxChange = this.handleCheckboxChange.bind(this);
        const onRangeChange = this.handleRangeChange.bind(this);

        if (this.select) {
            this.pushListener({ event: 'change', element: this.select, action: onSelectChange });
            this.handleSelectChange();
        }

        if (this.checkbox) {
            this.pushListener({ event: 'change', element: this.checkbox, action: onCheckboxChange });
            // this.handleCheckboxChange();
        }

        if (this.range) {
            this.pushListener({ event: 'change', element: this.range, action: onRangeChange });
        }
    }

    /**
     * Handles changes to the select element.
     * Toggles the 'tk-form-select--filled' class based on the select element's value.
     */
    handleSelectChange(): void {
        if (!this.select) return;
        this.classList.toggle('tk-form-select--filled', this.select.value !== '');
    }

    /**
     * Handles changes to the checkbox element.
     */
    handleCheckboxChange(): void {
        if (!this.checkbox) return;
        // Checkboxen
        if (this.checkbox.hasAttribute('data-checkbox-toggle-master')) {
            this.toggleAllCheckboxes(this.checkbox, this.checkbox.checked);
        }
        if (this.checkbox.hasAttribute('data-checkbox-toggle')) {
            this.toggleMasterCheckbox(this.checkbox);
        }
    }

    /**
     * Handles changes to the range element.
     */
    handleRangeChange(): void {
        if (!this.range) return;
        // Range Toggler
        if (this.range.hasAttribute('data-range-toggle-master')) {
            this.toggleAllRanges(this.range, this.range.value);
        }
        if (this.range.hasAttribute('data-range-toggle')) {
            // this.toggleMasterRange(this.range);
        }
    }

    // Checkboxes
    toggleAllCheckboxes(toggler : HTMLInputElement, status : boolean): void {
        const allCheckboxes : NodeListOf<HTMLInputElement> = document.querySelectorAll('input[data-checkbox-toggle]');
        Array.from(allCheckboxes).forEach((box) => {
            if (box.checked !== status && !box.disabled) {
                box.checked = status;
                // box.dispatchEvent(this.evt);
            }
        });
    }

    toggleMasterCheckbox(box : HTMLInputElement): void {
        const masterCheckbox : HTMLInputElement = document.querySelector('input[data-checkbox-toggle-master]')!;
        const isChecked : boolean = box.checked;
        if (!isChecked) {
            masterCheckbox.checked = false;
        }
    }

    // Range Toggler
    toggleAllRanges(toggler : HTMLInputElement, val : string): void {
        const allRanges: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[data-range-toggle]');
        Array.from(allRanges).forEach((range) => {
            if (range.value !== val && !range.disabled) {
                range.value = val;
                range.click();
                // range.dispatchEvent(this.evt);
            }
        });
    }

    toggleMasterRange(range : HTMLInputElement): void {
        const masterRange: HTMLInputElement = document.querySelector('input[data-range-toggle-master]')!;
        const val : number = Number(range.value);
        if (val) {
            masterRange.value = String(val);
        }
    }

    // #region Required
    checkRequiredAttribute() {
        if (!this.labelElement || !this.fieldElement || !this.fieldElement.hasAttribute('required') || this.labelElement.textContent.includes('*')) return;
        this.labelElement.textContent = `${this.labelElement.textContent} *`;
    }
}
