import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class KDMZFormIncreaser extends TKCustomElementFactory {
    rowElementSelector : string;
    rowElement? : HTMLElement;
    button? : HTMLButtonElement;
    maxRows : number;
    template? : HTMLElement;
    onlineEvent : Event;

    constructor() {
        super();
        this.rowElementSelector = this.getAttribute('data-row-element') || '';
        this.rowElement = this.querySelector(this.rowElementSelector) as HTMLElement;
        this.button = this.querySelector('[form-increaser-button]') as HTMLButtonElement;
        this.maxRows = Number(this.getAttribute('data-max-rows') || 10);
        this.template = this.rowElement.cloneNode(true) as HTMLElement;
        // delete all values
        const formfields = this.template.querySelectorAll('input');
        formfields.forEach((field) => {
            field.value = '';
        });
        // Erstelle ein neues Event-Objekt f�r das online-Event
        this.onlineEvent = new CustomEvent('fields-added');
    }

    connectedCallback() {
        if (this.template && this.button) {
            this.handleRemoveButton(this.rowElement);
            this.registerClickListener();
            this.checkRows();
        }
    }

    registerClickListener() {
        const handleClickListener = this.multiplyElement.bind(this);
        // this.button?.addEventListener('click', handleClickListener);

        this.pushListener({
            event: 'click',
            element: this.button,
            action: handleClickListener,
        });
    }

    handleRemoveButton(element : HTMLElement) {
        const deleteButtons = element.querySelectorAll('[data-increaser-remove]');
        deleteButtons.forEach((button) => {
            button && button.addEventListener('click', (e) => {
                const elemTarget = e.currentTarget as HTMLElement;
                const wrapper = elemTarget.closest('[data-form-row]') as HTMLElement;
                wrapper.remove();
                this.checkRows();
            });
        });
    }

    multiplyElement() {
        const countRows = this.querySelectorAll(this.rowElementSelector).length;
        if (countRows === this.maxRows) return;
        const copiedElement = this.template!.cloneNode(true) as HTMLElement;
        const lastElement = Array.from(this.querySelectorAll(this.rowElementSelector)).at(-1);
        lastElement!.insertAdjacentElement('afterend', copiedElement);
        this.handleRemoveButton(copiedElement);
        this.checkRows();

        //  JBI Todo: Trigger das online-Event auf dem window-Objekt --> wg. Validierung tk-form-validator
        window.dispatchEvent(this.onlineEvent);
    }

    checkRows() {
        const rows = this.querySelectorAll(this.rowElementSelector);
        const numberOfRows = rows.length;
        const deleteButton = this.querySelector('[data-increaser-remove]') as HTMLElement;
        deleteButton!.hidden = numberOfRows === 1;
        this.button!.hidden = numberOfRows === this.maxRows;

        rows.forEach((row, index) => {
            const fieldElements = row.querySelectorAll('input');
            const count = index + 1;
            fieldElements.forEach((field) => {
                const suffix = this.getSuffixNumber(field.name);
                if (!suffix) field.name += count;
                else suffix !== count && (field.name = field.name.replace(suffix, count));
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getSuffixNumber(name : string) {
        const match = name.match(/\d+$/);
        if (match) return parseInt(match[0], 10);
        return null;
    }
}
