import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';
import { isOutsideShapes } from '@tk/utilities/tk.shape.detector';

interface FocusHistoryItem {
    element: HTMLElement;
    index: number;
}

interface KeybindItem {
    keybind: string;
    action: () => void;
}

export default class TKQuickOrder extends TKCustomElementFactory {
    url?: string;
    fieldElement?: HTMLInputElement;
    resetButton?: HTMLButtonElement;
    submitButton?: HTMLButtonElement;
    resultElement?: HTMLElement;
    backButton?: HTMLButtonElement;
    activeClassName: string;
    hasDataClassName: string;
    loadingClassName: string;
    chooseKeybind: string;
    navigateUpKeybind: string;
    navigateDownKeybind: string;
    chooseQuantityKeybind: string;
    closeKeybind: string;
    timerId?: number;
    focusHistory: FocusHistoryItem[] = [];
    keybinds: KeybindItem[];
    allowedToClickBasketButton = true;

    constructor() {
        super();

        this.url = this.getAttribute('data-tk-url') || undefined;
        this.fieldElement = this.querySelector('input') || undefined;
        this.resetButton = this.querySelector<HTMLButtonElement>('[data-tk-quick-order-reset]') || undefined;
        this.submitButton = this.querySelector<HTMLButtonElement>('[data-tk-quick-order-submit]') || undefined;
        this.resultElement = this.querySelector('[data-tk-quick-order-result]') || undefined;
        this.backButton = this.querySelector<HTMLButtonElement>('[data-tk-quick-order-back]') || undefined;
        this.activeClassName = this.getAttribute('data-tk-active-class-name') || 'tk-search--active';
        this.hasDataClassName = this.getAttribute('data-tk-has-data-class-name') || 'tk-search--has-data';
        this.loadingClassName = this.getAttribute('data-tk-loading-class-name') || 'tk-search--loading';
        this.chooseKeybind = this.getAttribute('data-tk-choose-keybind') || 'Enter';
        this.navigateUpKeybind = this.getAttribute('data-tk-navigate-up-keybind') || 'ArrowUp';
        this.navigateDownKeybind = this.getAttribute('data-tk-navigate-down-keybind') || 'ArrowDown';
        this.chooseQuantityKeybind = this.getAttribute('data-tk-choose-quantity-keybind') || 'Enter';
        this.closeKeybind = this.getAttribute('data-tk-close-keybind') || 'Escape';
        this.keybinds = [
            { keybind: this.closeKeybind, action: () => this.hideDropdown() },
            { keybind: this.navigateUpKeybind, action: () => this.focusNextElement(true) },
            { keybind: this.navigateDownKeybind, action: () => this.focusNextElement() },
            { keybind: this.chooseQuantityKeybind, action: () => this.focusQuantity() },
            { keybind: this.chooseKeybind, action: () => this.clickBasketButton() },
        ];
    }

    connectedCallback(): void {
        if (
            !this.url
            || !this.fieldElement
            || !this.resetButton
            || !this.submitButton
            || !this.resultElement
            || !this.backButton
        ) throw new Error('TKQuickOrder: Elements are missing!');

        this.registerClickListener();
        this.registerFocusInListener();
        this.registerFocusOutListener();
        this.registerFocusListener();
        this.registerInputListener();
        this.registerKeybindListener();
    }

    registerClickListener() {
        const onClick = this.goBack.bind(this);
        this.pushListener({ event: 'click', element: this.backButton!, action: onClick });
    }

    registerFocusInListener() {
        const onFocusIn = this.showDropdown.bind(this);
        this.pushListener({ event: 'focusin', element: this.fieldElement!, action: onFocusIn });
    }

    registerFocusOutListener() {
        const onFocusOut = this.focusOut.bind(this);
        this.pushListener({ event: 'click', element: window, action: onFocusOut });
    }

    registerFocusListener() {
        const onFocus = this.focusFieldElement.bind(this);
        this.pushListener({ event: 'focus', element: this.fieldElement!, action: onFocus });
    }

    registerInputListener() {
        const onInput = this.search.bind(this);
        this.pushListener({ event: 'input', element: this.fieldElement!, action: onInput });
    }

    registerKeybindListener() {
        const onPress = this.handleKeybinds.bind(this);
        this.pushListener({ event: 'keydown', element: this, action: onPress });
    }

    prepareKeybinds() {
        const keybindMap: Map<string, (() => void)[]> = new Map();

        this.keybinds.forEach((item) => {
            if (!keybindMap.has(item.keybind)) {
                keybindMap.set(item.keybind, [item.action]);
            } else {
                keybindMap.get(item.keybind)?.push(item.action);
            }
        });

        return keybindMap;
    }

    distinctKeybinds() {
        const distinctList = new Set(this.keybinds.map((item) => item.keybind));
        return this.keybinds.length === distinctList.size;
    }

    goBack() {
        this.fieldElement!.value = '';
        this.resultElement!.innerHTML = '';
        this.hideDropdown();
    }

    showDropdown() {
        this.classList.add(this.activeClassName);
    }

    hideDropdown() {
        this.classList.remove(this.activeClassName);
    }

    focusOut(event: MouseEvent): void {
        if (!this.fieldElement || !this.resultElement) return;
        if (
            event.clientX === 0
            && event.clientY === 0
            && (
                event.target instanceof HTMLSelectElement
                || event.target instanceof HTMLOptionElement
                || (event.target as HTMLElement).hasAttribute('data-tk-basket-button')
            )
        ) return;
        const fieldElementRect = this.fieldElement.getBoundingClientRect();
        const resultRect = this.resultElement.getBoundingClientRect();
        const rectangles = [
            { rectangle: fieldElementRect },
            { rectangle: resultRect },
        ];
        if (isOutsideShapes(event, rectangles)) this.hideDropdown();
    }

    handleKeybinds(event: KeyboardEvent) {
        const keybindMap = this.prepareKeybinds();
        if (!keybindMap.has(event.key)) return;
        event.preventDefault();
        keybindMap.get(event.key)?.forEach((item) => item());
    }

    focusFieldElement() {
        this.focusHistory = [];
    }

    focusNextElement(reverse = false) {
        const childList = Array.from(this.resultElement!.children);
        const lastEntry = this.focusHistory.at(-1);
        let index = 0;
        if (lastEntry) {
            index = reverse ? lastEntry.index - 1 : lastEntry.index + 1;
        }
        const nextChild = childList.at(index) as HTMLElement;
        if (!nextChild) return;
        nextChild.focus();
        this.focusHistory.push({ element: nextChild, index });
    }

    getCurrentElement() {
        const { activeElement } = document;
        if (!activeElement) return;
        const closestElement = activeElement.closest<HTMLElement>('[data-tk-quick-order-tile]');
        let currentElement;
        if (activeElement.hasAttribute('data-tk-quick-order-tile')) {
            currentElement = activeElement as HTMLElement;
        } else if (closestElement) {
            currentElement = closestElement;
        } else {
            const firstChild = this.resultElement?.querySelector<HTMLElement>('[data-tk-quick-order-tile]');
            if (!firstChild) return;
            currentElement = firstChild;
        }
        return currentElement;
    }

    clickBasketButton() {
        if (!(this.allowedToClickBasketButton || this.distinctKeybinds())) return;
        const articleElement = this.getCurrentElement();
        if (!articleElement) return;
        const basketButton = articleElement.querySelector<HTMLButtonElement>('[data-tk-basket-button]');
        basketButton?.click();
        this.fieldElement?.select();
    }

    focusQuantity() {
        const articleElement = this.getCurrentElement();
        if (!articleElement) return;
        const fieldElements = articleElement
            .querySelectorAll<HTMLInputElement>('[data-tk-dimension]:not([hidden]) [data-tk-input-quantity]');

        const currentIndex = Array.from(fieldElements).findIndex((input) => input === document.activeElement);

        if (currentIndex !== -1) {
            const nextIndex = currentIndex + 1;
            if (nextIndex < fieldElements.length) {
                const nextFieldElement = fieldElements.item(nextIndex);
                nextFieldElement.select();
                this.allowedToClickBasketButton = false;
                return;
            }
        } else {
            fieldElements.item(0).select();
            this.allowedToClickBasketButton = false;
            return;
        }

        this.allowedToClickBasketButton = true;
    }

    search() {
        const { value } = this.fieldElement!;
        if (value.length < 3) {
            this.classList.remove(this.hasDataClassName);
            this.timerId && clearTimeout(this.timerId);
            return;
        }
        this.classList.add(this.loadingClassName);
        this.timerId && clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            const data = {
                value,
            };

            fetchRequest({
                requestURL: this.url!,
                resolveHandler: this.renderResult.bind(this),
                payload: data,
            });
        }, 500);
    }

    renderResult(response: TKResponse) {
        if (!response || !response.success) return;
        this.classList.remove(this.loadingClassName);
        const html = render(response.dataAsHtml);
        this.resultElement!.innerHTML = '';
        this.resultElement!.insertAdjacentHTML('afterbegin', html.innerHTML);
        html.childElementCount > 0 && this.classList.add(this.hasDataClassName);
    }
}
