import TKFileUploadArea from '@tk/controls/tk.file.upload.area';
import KDMZLoadingAnimation from '@/components/kdmz.loading.animation';

export default class KDMZFileUploadArea extends TKFileUploadArea {
    reloadAfterSuccess: boolean;

    constructor() {
        super();
        // eslint-disable-next-line max-len
        this.reloadAfterSuccess = !!(this.hasAttribute('data-kdmz-reload-after-success') && this.getAttribute('data-kdmz-reload-after-success') === 'true');
    }

    // overwritten from mother class
    async getResponseData(responseText: string, response: unknown) {
        if (response instanceof XMLHttpRequest && response.status === 200) {
            const response = await this.moveFile();
            const { path, success } = response.dataAsJson;
            if (!success) {
                const error = { message: 'FAILED!' };
                this.uppy?.emit('error', error);
                this.uppy?.emit('upload-cancel', error);
            } else {
                if (this.isWithinIFrame) {
                    window.parent.postMessage({
                        mceAction: 'close',
                        content: path,
                        size: {
                            width: 0,
                            height: 0,
                        },
                    }, '*');
                } else if (this.reloadAfterSuccess) {
                    KDMZLoadingAnimation.createLoadingScreen();
                    window.location.reload();
                }
                return {
                    url: path,
                };
            }
        }
    }
}
