export default class KDMZLoadingAnimation {
    static createLoadingScreen():void {
        const content = document.querySelector('body');
        const loadingDiv = document.createElement('div');
        loadingDiv.classList.add('kdmz-content-loading');
        if (content) {
            content.appendChild(loadingDiv);
        }
    }

    static destroyLoadingScreen():void {
        const content = document.querySelector('body');
        if (content) {
            const loadingDivs = document.querySelectorAll('body > .kdmz-content-loading');
            Array.from(loadingDivs).forEach((loadingDiv) => {
                content.removeChild(loadingDiv);
            });
        }
    }
}
